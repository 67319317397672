import { IdentifierType, TChassisIdentifier } from '@/types/entities/identifiers';
import { ReportSourcesItemState } from '@/types/entities/reportSourcesItem';

// TODO: search report constants in project and move it here
const GUARANTEE_STATUSES = {
  approved: 'есть',
  unapproved: 'нет',
  default: '',
  loading: 'в процессе',
};

// TODO sync mask with placeholder
export const PHONE_MASK = '+7 (###) ###-##-##';

// Seconds
export const MAX_TIME_TO_GENERATE_REPORT = 60;

export const PHONE_LENGTH = 18;
export const NORMALIZED_PHONE_LENGTH = 12;
export const NAME_LENGTH = 3;
export const MAX_NAME_LENGTH = 30;
export const PASSWORD_LENGTH = 6;

export const MAX_COMMENT_LENGTH = 150;

export const SHOWN_BLOCKS_IN_FULL_REPORT = 10;
export const MAX_COMMENTS_COUNT = 1;
export const FULL_REPORT_TYPE = 'avtocod_profi_full_report@avtocod';

export const TIME_MS_UPDATE_REPORT_FULL = 10000;
export const TIME_MS_UPDATE_REPORT_LIST = 10000;

export const SOURCE_STATES: readonly ReportSourcesItemState[] = ['OK', 'ERROR', 'PROGRESS'];

export const QUERY_TYPES: { [key in IdentifierType | TChassisIdentifier]: string } = {
  VIN: 'VIN',
  GRZ: 'Госномер',
  BODY: 'Номер кузова',
  CHASSIS: 'Шасси',
};

export default GUARANTEE_STATUSES;
