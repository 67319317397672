import { requireAuth } from '@/router/utils';
import { IdentifierType } from '@/types/entities/identifiers';

const CheckAuto = () => import('@/pages/desktop/check-auto/PageCheckAuto.vue');

export default [
  {
    path: '/check-auto',
    name: 'check-auto',
    component: CheckAuto,
    beforeEnter: requireAuth,
    meta: {
      title: 'Проверка авто',
    },
    props: (route: any) => ({
      type: route.query.type as IdentifierType,
      query: route.query.query as string,
      adsLink: route.query.ads_link,
      queryHashed: (route.query.hash === 'true') as boolean,
    }),
  },
];
